import React from "react";
import Heading from "../Heading";

const BubbleLayout = ({ id = null, heading, children }) => {
  return (
    <section className="bubble_layout" id={id}>
      <div className="pt-[5vw] pb-[12vw] md:px-[10%] px-[5%]">
        <Heading title={heading} colorClass={"text-tertiary"} />
        {children}
      </div>
    </section>
  );
};

export default BubbleLayout;
