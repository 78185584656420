import React from "react";

const Venue = () => {
  return (
    // <section className="no_bubble_layout mt-[-7em]">
    //   <div id="venue" />
    //   <Heading title={"Venue"} colorClass={"text-secondary"} />
    //   <h1 className="text-center my-[1em] text-[1em]">
    //     University of Engineering & Management, Kolkata
    //   </h1>
    //   <div className="w-full flex justify-center">
    //     <iframe
    //       title="uem-loca"
    //       className="map"
    //       src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3684.5005854099854!2d88.48770146491066!3d22.560373485189043!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a020b267a3cdc13%3A0xb3b21d652126f40!2sUniversity%20of%20Engineering%20%26%20Management%20(UEM)%2C%20Kolkata!5e0!3m2!1sen!2sin!4v1643981140662!5m2!1sen!2sin"
    //       allowfullscreen=""
    //       loading="lazy"
    //     ></iframe>
    //   </div>
    // </section>
    <section className="relative w-full h-full flex" id="venue">
      <iframe
        title="uem-loca"
        className="w-full h-full bg-gray-300"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3684.5005854099854!2d88.48770146491066!3d22.560373485189043!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a020b267a3cdc13%3A0xb3b21d652126f40!2sUniversity%20of%20Engineering%20%26%20Management%20(UEM)%2C%20Kolkata!5e0!3m2!1sen!2sin!4v1643981140662!5m2!1sen!2sin"
        loading="lazy"
      ></iframe>
    </section>
  );
};

export default Venue;
