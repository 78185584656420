import React, { useEffect } from "react";
import { Contact, Venue } from "../components";

const ContactPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="no_bubble_layout">
      <div className="w-full flex sm:flex-row flex-col gap-[5em]">
        {/* ====================== Left ====================== */}
        <div className="flex flex-col gap-[5em] sm:flex-[2]">
          {/* <div className="w-full">
            <img
              src={locationImg}
              alt="Loction of  UEM"
              className="w-full aspect-auto"
            />
          </div> */}
          <div className="w-full aspect-[3/2]">
            <Venue />
          </div>
        </div>

        {/* ====================== Right ====================== */}
        <div className="flex flex-col gap-[5em] sm:flex-1 h-auto">
          <Contact />
        </div>
      </div>
    </section>
  );
};

export default ContactPage;
