import React from 'react';
import NoBubbleLayout from '../Layout/NoBubbleLayout';
import { Link } from 'react-router-dom';

const prevConfDetails = [
  {
    confName: 'RAAISA 2023',
    link: 'https://raaisa2023.uem.edu.in/',
  },
];

const PrevConfSection = () => {
  return (
    <NoBubbleLayout heading={'Previous Conferences'}>
      <p className='text-center'>Check all our previous conferences here</p>
      <div className='flex justify-center'>
        {prevConfDetails.length > 0 ? (
          <div className='w-full max-w-[50rem] border-[2px] bg-white border-gray-200 rounded-[0.5em] flex flex-col sm:gap-0 gap-[1.5em] items-stretch mt-[1em] overflow-hidden text-[1em]'>
            {prevConfDetails.map((data, i) => (
              <Link
                to={data.link}
                target='_blank'
                className='flex sm:flex-row flex-col border-b-[2px] border-gray-200 last-of-type:border-b-0 divide-x-[2px] last-of-type:bg-gray-100 sm:divide-y-[0] divide-y-[2px] divide-gray-200 hover:bg-gray-200 group'
                key={i}
              >
                <div className='sm:w-auto w-full px-[2em] py-[0.5em] font-medium shrink-0'>
                  {data.confName}
                </div>

                <div className='flex-[3] divide-y-[2px] divide-gray-200 flex flex-col justify-around'>
                  {/* {data.regDatas.map((reg, i) => ( */}
                  <div
                    className='flex flex-row divide-x-[2px] divide-gray-200 h-full text-[0.8em]'
                    key={i}
                  >
                    <p className='flex items-center px-[0.5em] py-[0.8em] flex-[7] group-hover:text-blue-500'>
                      {/* {reg.statement} */}
                      {data.link}
                    </p>
                  </div>
                  {/* ))} */}
                </div>
              </Link>
            ))}
          </div>
        ) : (
          <p className='w-[100%] border-[2px] border-gray-200 rounded-[1em] flex items-center justify-center min-h-[10em] mt-[2em]'>
            No registration available
          </p>
        )}
      </div>
    </NoBubbleLayout>
  );
};

export default PrevConfSection;
