import React from "react";

const DataTable = ({ tableDataSet }) => {
  const { dataSet, tableHeads } = tableDataSet;
  return (
    <div className="rounded-[1em] bg-white overflow-hidden border-[2px] border-white text-[1em]">
      <div className="w-full sm:flex hidden flex-row divide-x-[2px] divide-white">
        {tableHeads.map((head, i) => (
          <div
            className={`${head.flexLength} px-[0.5em] bg-gray-700 text-tertiary`}
            key={i}
          >
            <h1 className="text-[1.2em]">{head.title}</h1>
          </div>
        ))}
      </div>
      <ul className="flex flex-col divide-y-[2px] divide-gray-300">
        {dataSet.map((data, i) => (
          <li
            className="sm:p-0 px-[1em] py-[0.8em] flex sm:flex-row flex-col sm:divide-x-[2px] divide-gray-200"
            key={i}
          >
            <div
              className={`${tableHeads[0].flexLength} px-[0.5em] py-[0.2em] sm:text-[1em] text-[1.3em] font-semibold`}
            >
              {data.posting}
            </div>
            <div className={`${tableHeads[1].flexLength}`}>
              {data.members.map((member, j) => (
                <p
                  key={j}
                  className="w-full px-[0.5em] sm:py-[0.2em] py-[0.8em] sm:text-[0.9em] text-[0.8em]"
                >
                  {member}
                </p>
              ))}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DataTable;
