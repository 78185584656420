import React, { useContext, createContext, useState } from "react";

const StateContext = createContext();

const ContextProvider = ({ children }) => {
  const [screenSize, setScreenSize] = useState(undefined);
  return (
    <StateContext.Provider value={{ screenSize, setScreenSize }}>
      {children}
    </StateContext.Provider>
  );
};

export default ContextProvider;

export const useStateContext = () => useContext(StateContext);
