import React, { useEffect } from "react";
import { PaperSubmission } from "../components";

const PaperSubmissionPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <PaperSubmission />
    </div>
  );
};

export default PaperSubmissionPage;
