import React from "react";
import latexTemplate from "../../assets/contributed-books.zip";
import wordTemplate from "../../assets/WordTemplate.zip";

const DownloadTemplate = ({ maxWidth, textSize, disableHeader = false }) => {
  return (
    <section
      className={` ${maxWidth ?? "max-w-[660px]"} w-full ${textSize ?? ""}`}
    >
      {!disableHeader && (
        <h1 className="text-[1.2em] text-center">Paper Templates</h1>
      )}
      <div className="mt-[1.5em] flex flex-col gap-y-[1em]">
        <TemplateDownloader
          text={"Template in Microsoft Word (US Letter)"}
          link={wordTemplate}
        />
        <TemplateDownloader
          text={"Template in LaTeX format"}
          link={latexTemplate}
        />
      </div>
    </section>
  );
};

export default DownloadTemplate;

export const TemplateDownloader = ({
  text,
  btnTitle = "Click to download",
  link,
}) => (
  <div className="flex md:flex-row flex-col gap-x-[2em] gap-y-[0.2em] md:justify-between justify-center items-center text-[0.8em]">
    <p className="2xs:whitespace-nowrap text-secondary text-center">{text}</p>

    <a
      href={link}
      download
      className={`w-max ${
        link ? "primary_btn cursor-pointer" : "disabled_btn pointer-events-none"
      } px-[1em] py-[0.2em] text-[0.8em] rounded-sm`}
    >
      {btnTitle}
    </a>
  </div>
);
