import React, { useEffect } from "react";
import {
  AreaOfInterest,
  BubbleLayout,
  Home,
  PrevConfSection,
} from "../components";

const HomePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Home />
      <BubbleLayout id={null} heading={"Conference Tracks"}>
        <AreaOfInterest />
      </BubbleLayout>
      <PrevConfSection />
    </div>
  );
};

export default HomePage;
