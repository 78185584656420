import React from 'react';
import Heading from '../Heading';
import { Link } from 'react-router-dom';
import { PrimaryLinkButton } from '../uielements/Buttons';
import { PAYMENT_LINK } from '../../Data/dummy';

const AccountDetails = () => {
  return (
    <section className='no_bubble_layout'>
      <Heading
        title={'Payment Details'}
        colorClass={'text-secondary md:text-[35px] sm:text-[25px] text-[16px]'}
      />
      <div className='flex flex-col items-center pt-[2em] gap-[0.5em]'>
        {/* <Link
          to="https://uem.edu.in/raaisa-online-payment/"
          target="_blank"
          className="py-[0.5em] px-[1.5em] rounded-[0.2em] shadow-md secondary_btn"
        >
          <p className="text-[0.8em] ">Click here to make payment</p>
        </Link> */}
        <PrimaryLinkButton target={'_blank'}>
          Will be Uploaded shortly...
        </PrimaryLinkButton>
      </div>
    </section>
  );
};

export default AccountDetails;
