import React, { useEffect, useState } from "react";
import { HiBars3 } from "react-icons/hi2";
import { ImHome3 } from "react-icons/im";
import { useStateContext } from "../../context/contextProvider";
import { Link } from "react-router-dom";

const NavMenuItems = ({ styleClass, manageNavMenu }) => {
  return (
    <>
      <Link
        to="/committee"
        className={styleClass}
        onClick={() => manageNavMenu(false)}
      >
        Committee
      </Link>
      <Link
        to="/authors-guidlines"
        className={styleClass}
        onClick={() => manageNavMenu(false)}
      >
        Author's Guidlines
      </Link>
      <Link
        to="/invited-speaker"
        className={styleClass}
        onClick={() => manageNavMenu(false)}
      >
        Invited Speaker
      </Link>
      <Link
        to="/registration"
        className={styleClass}
        onClick={() => manageNavMenu(false)}
      >
        Registration
      </Link>
      <Link
        to="/paper-submission"
        className={styleClass}
        onClick={() => manageNavMenu(false)}
      >
        Paper Submission
      </Link>
      <Link
        to="/program"
        className={styleClass}
        onClick={() => manageNavMenu(false)}
      >
        Program
      </Link>
      <Link
        to="/contact"
        className={styleClass}
        onClick={() => manageNavMenu(false)}
      >
        Contact
      </Link>
    </>
  );
};

const Navbar = () => {
  const { screenSize, setScreenSize } = useStateContext();
  const [activeMenu, setActiveMenu] = useState(false);

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="px_layout relative border-gray-300 border-b-[2px] shadow-md ">
      <nav className="navbar ">
        <Link
          to="/"
          className={`navbar_opt ${screenSize <= 900 && "max-w-[4em]"}`}
          onClick={() => setActiveMenu(false)}
        >
          <ImHome3 className="text-[1.5em]" />
        </Link>
        {screenSize <= 900 && (
          <button
            onClick={() => setActiveMenu((state) => !state)}
            className="nav_toggler"
          >
            <HiBars3 />
          </button>
        )}
        {screenSize > 900 ? (
          <NavMenuItems
            styleClass={"navbar_opt"}
            manageNavMenu={setActiveMenu}
          />
        ) : (
          activeMenu && (
            <div className="drop_navbar">
              <NavMenuItems
                styleClass={"drop_navbar_opt"}
                manageNavMenu={setActiveMenu}
              />
            </div>
          )
        )}
      </nav>
    </div>
  );
};

export default Navbar;
