import JKMImage from '../assets/JKM.jpeg';
import MikeImage from '../assets/Mike.jpeg';

export const CONFERENCE_EMAIL = 'raaisa2024@gmail.com';
export const SUBMITTION_LINK = 'https://cmt3.research.microsoft.com/RAAISA2024';
export const REGISTRATION_GOOGLE_LINK = 'https://forms.gle/fqWoJ3dWjB8Uo57s6';
export const PAYMENT_LINK = 'https://uem.edu.in/raaisa-online-payment/';

export const committeeData = {
  tableHeads: [
    { title: 'Position', flexLength: 'sm:w-[20%] w-full' },
    { title: 'Members', flexLength: 'sm:w-[80%] w-full' },
  ],
  dataSet: [
    {
      posting: 'Chief Patron',
      members: [
        'Prof. Satyajit Chakrabarti, Chancellor, University of Engineering and Management, Kolkata',
      ],
    },
    {
      posting: 'Patrons',
      members: [
        'Prof. Sajal Dasgupta, Vice Chancellor, University of Engineering and Management, Kolkata',
        'Prof. Satyajit Chakrabarti, Pro Vice Chancellor, University of Engineering and Management, Kolkata',
        'Prof. Mike Hinchey, University of Limerick, Ireland',
        'Prof. K. P. Ghatak, Dean(Engineering), University of Engineering and Management, Kolkata ',
        'Prof. Abir Chattopadhyay, Dean(Research), University of Engineering and Management, Kolkata ',
        'Prof. Rajiv Ganguly, Dean(Science), University of Engineering and Management, Kolkata ',
      ],
    },
    {
      posting: 'General Chair',
      members: ['Prof. Phillip Bradford, University of Connecticut, Stamford '],
    },
    {
      posting: 'General Co-Chair',
      members: [
        'Prof. Rajashree Paul, Director- IQAC, University of Engineering and Management, Kolkata ',
      ],
    },
    {
      posting: 'International Advisory Committee Chair',
      members: [
        'Prof. Nitin Kumar Tripathi, Asian Institute of Technology, Thailand',
      ],
    },
    {
      posting: 'Industry Academic Partnership Chair',
      members: [
        'Ms. Gopa Goswami, Director, Corporate Relations, University of Engineering and Management, Kolkata',
      ],
    },
    {
      posting: 'Industry Chair',
      members: [
        'Santosh Prasad, Partner & Service Line Leader and Sr. Location Executive, IBM',
      ],
    },

    {
      posting: 'Technical Program Committee Chair',
      members: [
        'Prof. Jyotsna K. Mandal, Professor, University of Kalyani, India',
        // 'Prof. Satyajit Chakrabarti, Pro Vice Chancellor, University of Engineering and Management, Kolkata',
      ],
    },

    {
      posting: 'Organizing Chair ',
      members: [
        'Prof. Sukalyan Goswami, Registrar, University of Engineering and Management, Kolkata',
      ],
    },
    {
      posting: 'Technical Program Committee Co-Chairs',
      members: [
        'Dr. Sudipta Sahana, Department of CSE (AI & ML), University of Engineering and Management, Kolkata',
        'Dr. Buddhadeb Pradhan, Department of CSE, University of Engineering and Management, Kolkata',
        'Dr. Sandip Mandal, Department of CSE (IoT, CS, BT), University of Engineering and Management, Kolkata',
      ],
    },
    {
      posting: 'Organizing Co-Chairs',
      members: [
        'Dr. Aniruddha Ghosh, Department of CSE(AI & ML), University of Engineering and Management, Kolkata',
        'Dr. Susmita Biswas, Department of CSE(IoT, CS, BT), University of Engineering and Management, Kolkata',
        'Ms. Bipasha Mukhopadhyay, Department of CSE, University of Engineering and Management, Kolkata',
        'Ms. Varsha Poddar, Department of CSE, University of Engineering and Management, Kolkata',
        'Mr. Nilanjan Chatterjee, Department of CSE, University of Engineering and Management, Kolkata',
      ],
    },
    {
      posting: 'Organizing Committee Members',
      members: [
        'Mr. Sudipto Dhar, Department of CSE, University of Engineering and Management, Kolkata',
        // 'Ms. Bidisha Bhabani, Department of CSE, University of Engineering and Management, Kolkata',
        'Mr. Angshuman Ray, Department of CSE, University of Engineering and Management, Kolkata',
        'Dr. Ramen Pal, Department of CSE(AI & ML), University of Engineering and Management, Kolkata',
        'Mr. Aniruddha Das, Department of CSE(AI & ML), University of Engineering and Management, Kolkata',
        'Ms. Anasuya Sengupta, Department of CSE(AI & ML), University of Engineering and Management, Kolkata',
        'Ms. Sramana Mukherjee, Department of CSE(AI & ML), University of Engineering and Management, Kolkata',
        'Ms. Srishti Dey, Department of CSE(AI & ML), University of Engineering and Management, Kolkata',
        'Mr. Amit Kumar, Department of CSE(AI & ML), University of Engineering and Management, Kolkata',
        'Dr. Siddhartha Roy, Department of CSE(IoT, CS, BT), University of Engineering and Management, Kolkata',
        'Ms. Sweta Saha, Department of CSE, University of Engineering and Management, Kolkata',
        'Mr. Arijeet Ghosh, Department of CSE(IoT, CS, BT), University of Engineering and Management, Kolkata',
        'Mr. Avik Kumar Das, Department of CSE(IoT, CS, BT), University of Engineering and Management, Kolkata',
        // 'Ms. Neha Sana Ghosh, Department of CSE(IoT, CS, BT), University of Engineering and Management, Kolkata',
        'Mr. Harshit Srivastava, Department of CSE(AI & ML), University of Engineering and Management, Kolkata',
        'Ms. Purbita Chatterjee, , Department of CSE(AI & ML), University of Engineering and Management, Kolkata',
        'Ms. Namrata Shaw, Department of CSE(AI & ML), University of Engineering and Management, Kolkata',
        'Mr. Ranjan Kumar Mandal, Department of CSE, University of Engineering and Management, Kolkata',
        'Mr. Ranadhir Das, Department of CSE, University of Engineering and Management, Kolkata',
        'Prof. Dipankar Datta, Department of CSE(IoT, CS, BT), University of Engineering and Management, Kolkata',
        'Prof. Suchanda Chatterjee, Department of CSE(IoT, CS, BT), University of Engineering and Management, Kolkata',
        'Prof. Rangon Sarkar, Department of CSE(IoT, CS, BT), University of Engineering and Management, Kolkata',
        'Prof. Apurba Nandi, Department of CSE(IoT, CS, BT), University of Engineering and Management, Kolkata',
        'Prof. (Dr.) Sandgita Dutta, Department of CSE(IoT, CS, BT), University of Engineering and Management, Kolkata',
        'Prof. Sayantani Das, Department of CSE(IoT, CS, BT), University of Engineering and Management, Kolkata'
      ],
    },
    {
      posting: 'Finance Chair',
      members: [
        'Mr. Indranil Banerjee, CFO, University of Engineering and Management, Kolkata',
      ],
    },
    {
      posting: 'Registration and Reception Chair',
      members: [
        'Mr. Sudipto Kumar Mondal, Department of CSE, University of Engineering and Management, Kolkata',
      ],
    },
    {
      posting: 'Publicity Chair',
      members: [
        'Mr. Anay Ghosh, Department of CSE, University of Engineering and Management, Kolkata',
      ],
    },
    {
      posting: 'Publicity Co-Chairs',
      members: [
        'Ms. Sukanya Roy, Department of CSE, University of Engineering and Management, Kolkata',
        'Mr. Sumit Anand, Department of CSE, University of Engineering and Management, Kolkata',
      ],
    },
    {
      posting: 'Refreshments and Transport',
      members: [
        'Mr. Bapi Biswas, Department of CSE, University of Engineering and Management, Kolkata',
      ],
    },
  ],
};

export const techCommitteeData = {
  subheaderData: [
    {
      name: 'Dr. Debashis De',
      info: 'Maulana Abul Kalam Azad University of Technology, India',
    },
    {
      name: 'Dr. Jyoti Sekhar Banerjee',
      info: 'Bengal Institute of Technology, India',
    },
  ],
  tableHeads: [
    { title: 'Name', flexLength: 'sm:min-w-[22%] sm:max-w-[15%] min-w-full' },
    {
      title: 'Affiliation',
      flexLength: 'sm:min-w-[60%] sm:max-w-[56%] min-w-full',
    },
    {
      title: 'Country',
      flexLength: 'sm:min-w-[15%] sm:max-w-[22%] min-w-full',
    },
  ],
  dataSet: [
    {
      name: 'Dr. Amit Majumder',
      affiliation: 'NIT Jamshedpur',
      country: 'India',
    },
    {
      name: 'Dr. Amiya Kumar Rath',
      affiliation: 'Veer Surendra Sai University of Technology, India',
      country: 'India',
    },
    {
      name: 'Dr. Andrew M. Lynn',
      affiliation: 'Jawaharlal Nehru University, New Delhi ',
      country: 'India',
    },
    {
      name: 'Dr. Angsuman Sarkar',
      affiliation: 'Kalyani Government Engineering College',
      country: 'India',
    },
    {
      name: 'Dr. Anirban Mukhopadhyay',
      affiliation: 'University of Kalyani',
      country: 'India',
    },
    {
      name: 'Mr. Arijit Nandi',
      affiliation: 'Big data and Data Science Unit, Eurecat Barcelona',
      country: 'Spain',
    },
    {
      name: 'Dr. Arnab K. Laha',
      affiliation: 'Indian Institute of Management Ahmedabad',
      country: 'India',
    },
    {
      name: 'Dr. Ashok Kumar Das',
      affiliation:
        'International Institute of Information Technology, Hyderabad',
      country: 'India',
    },
    {
      name: 'Dr. Asif Ekbal',
      affiliation: 'Indian Institute of Technology Patna',
      country: 'India',
    },
    {
      name: 'Dr. Avisankar Roy',
      affiliation: 'Haldia Institute of Technology',
      country: 'India',
    },
    {
      name: 'Dr. Balakrushna Tripathy',
      affiliation: 'Vellore Institute of Technology',
      country: 'India',
    },
    {
      name: 'Dr. Banshidhar Majhi',
      affiliation: 'National Institute of Technology Rourkela',
      country: 'India',
    },
    {
      name: 'Dr. Bhaba R. Sarker',
      affiliation: 'Louisiana State University',
      country: 'USA',
    },
    {
      name: 'Dr. Bhabani P Sinha',
      affiliation: 'Indian Statistical Institute Kolkata',
      country: 'India',
    },
    {
      name: 'Dr. Bikramjit Sarkar',
      affiliation: 'JIS College of Engineering',
      country: 'India',
    },
    {
      name: 'Dr. Biplab K. Sikdar',
      affiliation:
        'Indian Institute of Engineering Science and Technology Shibpur',
      country: 'India',
    },
    {
      name: 'Dr. Biswapati Jana',
      affiliation: 'Vidyasagar University',
      country: 'India',
    },
    {
      name: 'Dr. Bubu Bhuyan',
      affiliation: 'North Eastern Hill University',
      country: 'India',
    },
    {
      name: 'Dr. Celia Shahnaz',
      affiliation:
        'Bangladesh University of Engineering and Technology (BUET) Dhaka',
      country: 'Bangladesh',
    },
    {
      name: 'Dr. Chih-Yung Chang',
      affiliation: 'Tamkang University',
      country: 'Taiwan',
    },
    {
      name: 'Dr. Chilukuri K. Mohan',
      affiliation: 'Syracuse University',
      country: 'USA',
    },
    {
      name: 'Dr. Dacheng Tao',
      affiliation: 'The University of Sydney',
      country: 'Sydney, Australia',
    },
    {
      name: 'Dr. Debashis De',
      affiliation: 'Maulana Abul Kalam Azad University of Technology',
      country: 'India',
    },
    {
      name: 'Dr. Debiao He',
      affiliation: 'Wuhan University',
      country: 'China',
    },
    {
      name: 'Dr. Debotosh Bhattacharjee',
      affiliation: 'Jadavpur University',
      country: 'India',
    },
    {
      name: 'Dr. Dharmpal Singh',
      affiliation: 'JIS University',
      country: 'India',
    },
    {
      name: 'Dr. Diganta Sengupta',
      affiliation: 'Meghnad Saha Institute of Technology',
      country: 'India',
    },
    {
      name: 'Dr. Pranab K Dan',
      affiliation: 'IIT Kharagpur',
      country: 'India',
    },
    {
      name: 'Dr. Ekramul Hamid',
      affiliation: ' University of Rajshahi',
      country: 'Bangladesh',
    },
    {
      name: 'Dr. Eman Abdelfattah ',
      affiliation: 'Sacred Heart University',
      country: 'United States',
    },
    {
      name: 'Dr. Hadj Bourdoucen',
      affiliation: 'Sultan Qaboos University',
      country: 'Oman',
    },
    {
      name: 'Dr. Heba Mohamed Elgazzar ',
      affiliation: 'Morehead State University',
      country: 'Morehead, Kentucky',
    },
    {
      name: 'Dr. Indrajit Bhattacharya',
      affiliation: 'Kalyani Govt. Engineering College',
      country: 'India',
    },
    {
      name: 'Dr. Jyoti Sekhar Banerjee',
      affiliation: 'Bengal Institute of Technology',
      country: 'India',
    },
    {
      name: 'Dr. Kartick Chandra Mandal',
      affiliation: 'Jadavpur University',
      country: 'India',
    },
    {
      name: 'Dr. Kaushik Roy',
      affiliation: 'Barasat State University',
      country: 'India',
    },
    {
      name: 'Dr. Khondekar Lutful Hassan ',
      affiliation: 'Aliah University',
      country: 'India',
    },
    {
      name: 'Dr. Layth Silman',
      affiliation:
        'French Engineering School of Information Science and Technology',
      country: 'Paris, France',
    },
    {
      name: 'Dr. Malay Bhattacharyya',
      affiliation:
        'Indian Institute of Engineering Science and Technology, Shibpur',
      country: 'India',
    },
    {
      name: 'Dr. Matin Pirouz',
      affiliation: 'California State University, Fresno',
      country: 'CA, USA',
    },
    {
      name: 'Dr. Md Ali',
      affiliation: 'Rider University',
      country: ' New Jersey, USA',
    },
    {
      name: 'Dr. Mehdi Adda ',
      affiliation: 'Université du Québec à Rimouski',
      country: 'Canada',
    },
    {
      name: 'Dr. Mohammad S. Khan',
      affiliation: 'East Tennessee State University',
      country: 'Johnson City, TN, USA',
    },
    {
      name: 'Dr. Muath Obaidat ',
      affiliation: 'City University of New York',
      country: 'New York',
    },
    {
      name: 'Dr. Nabendu Chaki',
      affiliation: 'University of Calcutta',
      country: 'India',
    },
    {
      name: 'Dr. Partha Pratim Sahu',
      affiliation: 'Tezpur University',
      country: 'India',
    },
    {
      name: 'Dr. Prasan Kumar Sahoo',
      affiliation: 'Chang Gung University',
      country: 'Taoyuan, 33302, Taiwan',
    },
    {
      name: 'Dr. Prasanta K Jana',
      affiliation: 'Indian Institute of Technology (ISM) Dhanbad',
      country: 'India',
    },
    {
      name: 'Dr. Raj Jain',
      affiliation: 'Washington University ',
      country: 'USA',
    },
    {
      name: 'Dr. Reinhard GERNDT',
      affiliation: 'Ostfalia University of Applied Sciences',
      country: 'Wolfenbüttel, Germany',
    },
    {
      name: 'Dr. Renato Racelis Maaliw III',
      affiliation: 'Southern Luzon State University',
      country: 'Philippines',
    },
    {
      name: 'Dr. Sankhayan Choudhuri',
      affiliation: 'University of Calcutta',
      country: 'India',
    },
    {
      name: 'Dr. Seyed Jafar Sadjadi',
      affiliation: 'Iran University of Science and Technology',
      country: 'Iran',
    },
    {
      name: 'Dr. Shahab Tayeb',
      affiliation: 'California State University, Fresno',
      country: 'CA, USA',
    },
    {
      name: 'Dr. Sharmila An John Francis',
      affiliation: 'King Khalid University',
      country: 'Abha, Saudi Arabia',
    },
    {
      name: 'Dr. Shikhar Verma',
      affiliation: 'Tohoku University',
      country: 'Japan',
    },
    {
      name: 'Dr. Shrikant Jadhav ',
      affiliation: 'San Jose State University',
      country: 'California, United States',
    },
    {
      name: 'Dr. Somnath Mukhopadhyay',
      affiliation: 'Assam University',
      country: 'India',
    },
    {
      name: 'Dr. Sudipta Roy',
      affiliation: 'Assam University',
      country: 'India',
    },
    {
      name: 'Dr. Sue-Chen Hsueh',
      affiliation: 'Chaoyang University of Technology',
      country: 'Taiwan, R.O.C',
    },
    {
      name: 'Dr. Swagatam Das',
      affiliation: 'Indian Statistical Institute Kolkata',
      country: 'India',
    },
    {
      name: 'Dr. Swapan Kumar Mandal',
      affiliation: 'Kalyani Government Engineering College',
      country: 'India',
    },
    {
      name: 'Dr. Tandra Pal',
      affiliation: 'National Institute of Technology Durgapur',
      country: 'India',
    },
    {
      name: 'Dr. Udoinyang G. Inyang',
      affiliation: 'University of Uyo',
      country: 'Akwa Ibom State Nigeria',
    },
    {
      name: 'Dr. Uduak A. Umoh',
      affiliation: 'University of Uyo',
      country: 'Akwa Ibom State Nigeria',
    },
    {
      name: 'Dr. Ugo Fiore',
      affiliation: 'University of Napoli',
      country: 'Napoli, Italy',
    },
    {
      name: 'Dr. Vijayakumar Varadarajan',
      affiliation: 'The University of New South Wales',
      country: 'Sydney, Australia',
    },
    {
      name: 'Dr. Vladimir Oleshchuk',
      affiliation: 'University of Agder',
      country: 'Norway',
    },
    {
      name: 'Dr. Waleed Alnumay',
      affiliation: 'King Saud University',
      country: 'Riyadh, Saudi Arabia',
    },
    {
      name: 'Dr.  Wudhichart  Sawangphol',
      affiliation: 'Mahidol University',
      country: 'Thailand',
    },
    {
      name: 'Dr. Xiao Liu',
      affiliation:
        'School of Information Technology, Deakin University, Geelong',
      country: 'Australia',
    },
    {
      name: 'Dr. Xiao-Zhi Gao',
      affiliation: 'University of Eastern Finland',
      country: 'Kuopio, Finland',
    },
    {
      name: 'Dr. Xing Liu',
      affiliation: 'Kwantlen Polytechnic University',
      country: 'Vancouver, Canada',
    },
    {
      name: 'Dr. Yingwei Wang',
      affiliation: 'University of Prince Edward Island',
      country: 'Canada ',
    },
  ],
};

export const advisoryCommittee = {
  tableHeads: [
    { title: 'Name', flexLength: 'sm:min-w-[22%] sm:max-w-[15%] min-w-full' },
    {
      title: 'Affiliation',
      flexLength: 'sm:min-w-[60%] sm:max-w-[56%] min-w-full',
    },
    {
      title: 'Country',
      flexLength: 'sm:min-w-[15%] sm:max-w-[22%] min-w-full',
    },
  ],
  dataSet: [
    {
      name: 'Dr. Hamidah Ibrahim',
      affiliation: 'University Putra Malaysia',
      country: 'Malaysia',
    },
    {
      name: 'Dr. Indranil Sengupta',
      affiliation: 'Indian Institute of Technology, Kharagpur',
      country: 'India',
    },
    {
      name: 'Dr. Jerzy Szymanski',
      affiliation: 'University of Technology and Humanities, Radom',
      country: 'Poland',
    },
    {
      name: 'Dr. Jimson Mathew',
      affiliation: 'Indian Institute of Technology Patna',
      country: 'India',
    },
    {
      name: 'Dr. Kaushik DasSharma',
      affiliation: 'University of Calcutta',
      country: 'India',
    },
    {
      name: 'Dr. Masaaki Nagahara',
      affiliation: 'The University of Kitakyushu',
      country: 'Japan',
    },
    {
      name: 'Dr. Mohammad Kaykobad',
      affiliation: 'Bangladesh University of Engineering & Technology',
      country: 'Bangladesh',
    },
    {
      name: 'Dr. Oscar Castillo',
      affiliation: 'Tijuana Institute of Technology',
      country: 'USA',
    },
    {
      name: 'Dr. Paramartha  Dutta',
      affiliation: 'Visva-Bharati  (Central University)',
      country: 'India',
    },
    {
      name: 'Dr. Pieter J. A. Harpe',
      affiliation: 'Eimdhoven University of Technology',
      country: 'Netherlands',
    },
    {
      name: 'Dr. R. Sridevi',
      affiliation: 'JNTUH University',
      country: 'India',
    },
    {
      name: 'Dr. S. P.  Maity',
      affiliation:
        'Indian Institute of Engineering Science and Technology, Shibpur',
      country: 'India',
    },
    {
      name: 'Dr. Satish K. Sharma',
      affiliation: 'San Diego State Univ',
      country: 'USA',
    },
    {
      name: 'Dr. Valentina Balas',
      affiliation: 'Aurel Vlaicu University of Arad',
      country: 'Romania',
    },
  ],
};

export const importantDates = [
  {
    event: 'Full Paper Submission',
    date: '20th September, 2024',
  },
  {
    event: 'Notification of Acceptance',
    date: '15th October, 2024',
    // crossDate: '10th November, 2023',
  },
  {
    event: 'Camera Ready Paper Submission & Author Registration',
    date: 'On or before 15th November, 2024',
  },
  // {
  //   event: 'Last Date of Early Bird Registration',
  //   date: '20th November, 2023',
  //   crossDate: '15th November, 2023',
  // },
  {
    event: 'Conference Dates',
    date: '14th – 15th December, 2024',
  },
];

export const speakers = [
  {
    image: JKMImage,
    name: 'Prof. (Dr.) Jyotsna K. Mandal',
    affiliation: 'University of Kalyani, India',
    biography:
      'Coding Theory, Data and Network Security, Remote Sensing & GIS based Applications, Data Compression Error Corrections, Information security, Watermarking, Steganography and many more...',
    link: 'https://scholar.google.co.in/citations?user=NIlye4UAAAAJ&hl=en',
  },
  {
    image: MikeImage,
    name: 'Prof. (Dr.) Mike Hinchey',
    affiliation: 'University of Limerick, Ireland',
    biography:
      '​Mike Hinchey is Professor of Software Engineering at University of Limerick, Ireland. Prior to joining Lero, Professor Hinchey was Director of the NASA Software Engineering Laboratory.',
    link: 'https://www.itu.int/en/ITU-T/AI/Pages/hinchey.aspx',
  },
];

export const sponsers = [
  { type: 'Platinum sponser', amount: 'Rs 5 lakh' },
  { type: 'Diamond sponser', amount: 'Rs 4 lakh' },
  { type: 'Gold sponser', amount: 'Rs 3 lakh' },
  { type: 'Silver sponser', amount: 'Rs 2 lakh' },
];

export const areaOfInterestList = [
  {
    name: 'Track 1 – Artificial Intelligence',
    applications: [
      'Smart Agent-based Systems',
      'Human-Computer Interaction Technologies',
      'Reinforcement Learning',
      'Sentiment Analysis',
      'Recurrent Neural Networks and its Applications',
      'Genetic Algorithm Neural Networks',
    ],
  },
  {
    name: 'Track 2 – Smart Systems',
    applications: [
      'Deep Learning based Smart Systems',
      'Security, Privacy, and Trust issues in Smart Computing',
      'Smart Computing for Cyber-physical Systems',
      'Augmented Reality/ Virtual Reality',
      'AI/ML and IoT',
    ],
  },
];

export const registrationDetials = [
  {
    personType: 'Foreign Author (Academia / Industry)',
    regDatas: [
      {
        statement: 'Regular registration',
        fees: '$ 100',
      },
      {
        statement: 'Late registration',
        fees: '$ 150',
      },
    ],
  },
  {
    personType: 'Indian Author (Academia / Research Scholar)',
    regDatas: [
      {
        statement: 'Regular registration',
        fees: '₹ 4000',
      },
      {
        statement: 'Late registration',
        fees: '₹ 4500',
      },
    ],
  },

  {
    personType: 'Industry Author',
    regDatas: [
      {
        statement: 'Registration',
        fees: '₹ 5000',
      },
    ],
  },
  {
    personType: 'Participants',
    regDatas: [
      {
        statement: 'Academician / Industry Person',
        fees: '₹ 1000',
      },
      {
        statement: 'Students / Research Scholar',
        fees: '₹ 500',
      },
    ],
  },
];
