import React from "react";
import Heading from "../Heading";
import DataTable from "../DataTable";
import {
  committeeData,
  techCommitteeData,
  advisoryCommittee,
} from "../../Data/dummy";
import DataTable2 from "../DataTable2";
import BubbleLayout from "../Layout/BubbleLayout";

const Committee = () => {
  return (
    <BubbleLayout id={"committee"} heading={"Conference Committee"}>
      <div className="mt-[5vw] sm:text-[14px] text-[12px] mb-[5em]">
        <DataTable tableDataSet={committeeData} />
      </div>
      <Heading title={"Advisory Committee"} colorClass={"text-tertiary"} />
      <div className="mt-[5vw] sm:text-[14px] 2xs:text-[12px] text-[8px] mb-[5em]">
        <DataTable2 tableDataSet={advisoryCommittee} />
      </div>
      <Heading
        title={"Technical Program Committee"}
        colorClass={"text-tertiary"}
      />
      {/* <div className="flex flex-col items-center text-white px-[3em]">
        <h1>Chair (s)</h1>
        <SubHeadingTable dataList={techCommitteeData.subheaderData} />
      </div> */}
      <div className="mt-[5vw] sm:text-[14px] 2xs:text-[12px] text-[8px]">
        <DataTable2 tableDataSet={techCommitteeData} />
      </div>
    </BubbleLayout>
  );
};

export default Committee;

const SubHeadingTable = ({ dataList }) => {
  return (
    <div className="w-full grid grid-flow-row grid-cols-[200px_,1fr]">
      {dataList.map((data, i) => (
        <>
          <p className="font-semibold" key={`name-${i}`}>
            {data.name}
          </p>
          <p className="font-normal text-[0.9em]" key={`info-${i}`}>
            {data.info}
          </p>
        </>
      ))}
    </div>
  );
};
