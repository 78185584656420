import React, { useEffect } from 'react';
import { Committee } from '../components';

const CommitteePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Committee />
    </div>
  );
};

export default CommitteePage;
