import React from 'react';

const Heading = ({ colorClass, title }) => {
  return (
    <h1
      className={`text-center md:text-[50px] sm:text-[35px] text-[20px] ${colorClass}`}
    >
      {title}
    </h1>
  );
};

export default Heading;
