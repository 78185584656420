import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Layout } from './components';
import {
  HomePage,
  AuthorsGuidLinePage,
  CommitteePage,
  ContactPage,
  InvitedSpeakerPage,
  PaperSubmissionPage,
  ProgramPage,
  RegistrationPage,
} from './pages';

function App() {
  return (
    <BrowserRouter>
      <Layout>
        <div>
          <Routes>
            <Route path='/' element={<HomePage />} />
            <Route path='/committee' element={<CommitteePage />} />
            <Route
              path='/authors-guidlines'
              element={<AuthorsGuidLinePage />}
            />
            <Route path='/invited-speaker' element={<InvitedSpeakerPage />} />
            <Route path='/registration' element={<RegistrationPage />} />
            <Route path='/paper-submission' element={<PaperSubmissionPage />} />
            <Route path='/program' element={<ProgramPage />} />
            <Route path='/contact' element={<ContactPage />} />
          </Routes>
        </div>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
