import React from "react";
import TopSection from "../header/NavTopSection";
import Navbar from "../header/Navbar";
import Footer from "../footer/Footer";

const Layout = ({ children }) => {
  return (
    <div className="min-h-screen flex flex-col">
      <TopSection />
      <header className="sticky top-0 left-0 bg-tertiary z-[100]">
        <Navbar />
      </header>
      <main>{children}</main>
      <footer className="mt-auto">
        <Footer />
      </footer>
    </div>
  );
};

export default Layout;
