import React from 'react';
import { ImLocation } from 'react-icons/im';
import { AiFillMail } from 'react-icons/ai';
import { FiLink } from 'react-icons/fi';
import { CONFERENCE_EMAIL } from '../../Data/dummy';

const Contact = () => {
  return (
    <section
      id='contact'
      className='text-black max-w-[500px] flex flex-col sm:gap-0 gap-[2em] h-full'
    >
      {/* ============================== Contact ============================== */}
      <div>
        <h1 className='font-semibold text-[2em] sm:text-start text-center text-secondary'>
          Contact
        </h1>
        <div className='flex flex-col w-full gap-[0.5em] mt-[1em] sm:items-start items-center'>
          {/* <div className="text-[1em] flex sm:flex-row flex-col gap-[1em] items-center">
          <FaPhone />
          <span className="flex sm:flex-row flex-wrap flex-col sm:gap-[em] ">
            <p className="text-center">+918274088608 ,</p>
            <p className="text-center">+918010700500 ,</p>
            <p className="text-center">+9103323572059 ,</p>
            <p className="text-center">+9103323572995 </p>
          </span>
         </div> */}
          <div className='text-[1em] flex sm:flex-row flex-col gap-x-[1em] gap-y-[0.2em] sm:items-start items-center'>
            <ImLocation className='mt-[0.2em]' />
            <p className='w-[80%] sm:text-start text-center'>
              University Area, Plot No III-B/5, Main Arterial Road, New Town
              Action Area – III, Kolkata – 700160
            </p>
          </div>
          <div className='email'>
            <AiFillMail />
            <p>{CONFERENCE_EMAIL}</p>
          </div>
          <div className='email'>
            <AiFillMail />
            <p>jkmandal@klyuniv.ac.in</p>
          </div>
          <div className='email'>
            <AiFillMail />
            <p>sukalyan.goswami@uem.edu.in</p>
          </div>
          <div className='email'>
            <AiFillMail />
            <p>sudipta.sahana@uem.edu.in</p>
          </div>
        </div>
      </div>
      {/* ============================== Website ============================== */}
      <div className='mt-auto'>
        <h1 className='font-semibold text-[1.2em] mt-[1em] text-secondary sm:text-start text-center'>
          University Website
        </h1>
        <div className='text-[1em] flex sm:flex-row flex-col gap-x-[1em] gap-y-[0.2em] items-center mt-[1em]'>
          <FiLink />
          <a
            href='https://uem.edu.in/uem-kolkata'
            className='sm:text-start text-center hover:text-blue-400'
            rel='noreferrer'
            target='_blank'
          >
            uem.edu.in
          </a>
        </div>
      </div>
    </section>
  );
};

export default Contact;
