import React from "react";
import { BsFacebook, BsYoutube } from "react-icons/bs";
import { Link } from "react-router-dom";
// import Contact from "./Contact";
// import Venue from "../sections/Venue";

const Footer = () => {
  return (
    <section className="bg-gray-800 mt-[20px] md:text-[20px] 2xs:text-[16px] text-[10px]">
      <div className="py-[1.5em] md:px-[10%] px-[5%] ">
        <h1 className="text-center text-white">Our Social media</h1>
        <div className="flex items-center justify-center gap-[20px] text-white text-[1.5em] mt-[0.5em]">
          <Link
            to="https://www.facebook.com/uemuniversity"
            target="_blank"
            className="social_link"
          >
            <BsFacebook />
          </Link>
          <Link
            to="https://www.youtube.com/channel/UCYtrTm4H9aA39wK2DeahpXw"
            target="_blank"
            className="social_link"
          >
            <BsYoutube />
          </Link>
        </div>
      </div>
      <div className="text-center text-[0.7em] py-[2em] tracking-[0.1em] border-t-[1px] border-gray-600 text-gray-300">
        &copy; copyright UEM 2024 - All rights reserved
      </div>
    </section>
  );
};

export default Footer;
