import React from "react";
import { registrationDetials } from "../Data/dummy";

const RegistrationTable = () => {
  return (
    <>
      {registrationDetials.length > 0 ? (
        <div className="w-[100%] border-[2px] bg-white border-gray-200 rounded-[1em] flex flex-col sm:gap-0 gap-[1.5em] items-stretch mt-[1em] overflow-hidden text-[1em]">
          {registrationDetials.map((data, i) => (
            <div
              className="flex sm:flex-row flex-col border-b-[2px] border-gray-200 last-of-type:border-b-0 divide-x-[2px] last-of-type:bg-gray-100 sm:divide-y-[0] divide-y-[2px] divide-gray-200"
              key={i}
            >
              <div className="sm:flex-1 sm:w-auto w-full px-[0.5em] py-[1em] font-medium">
                {data.personType}
              </div>

              <div className="flex-[3] divide-y-[2px] divide-gray-200 flex flex-col justify-around">
                {data.regDatas.map((reg, i) => (
                  <div
                    className="flex flex-row divide-x-[2px] divide-gray-200 h-full text-[0.8em]"
                    key={i}
                  >
                    <p className="flex items-center px-[0.5em] py-[0.8em] flex-[7]">
                      {reg.statement}
                    </p>
                    <p className="flex items-center flex-[2] px-[0.5em] text-[1em] font-medium">
                      {reg.fees}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p className="w-[100%] border-[2px] border-gray-200 rounded-[1em] flex items-center justify-center min-h-[10em] mt-[2em]">
          No registration available
        </p>
      )}
    </>
  );
};

export default RegistrationTable;
