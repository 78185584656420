import React from "react";
import logo from "./../assets/uem_logo.png";
import springerLogo from "./../assets/springer_transparent.png";
import scopusLogo from "./../assets/scopus.jpeg";
import { Link } from "react-router-dom";

const HeadBanner = () => {
  return (
    <section
      className="bg-[url('./assets/headbannerbg.jpeg')] bg-cover bg-center curve_end lg:text-[20px] xs:text-[16px] 2xs:text-[12px] text-[8px]"
      id=" "
    >
      <div className="xs:min-h-screen pt-[2em] md:w-[80%] w-full m-auto bg-gray-50/60 px-[5%]">
        <div className="flex">
          <img
            src={springerLogo}
            alt="springer logo"
            className="h-[5em] m-auto"
          />
          <img
            src={scopusLogo}
            alt="springer logo"
            className="h-[4em] m-auto"
          />
        </div>
        <h1 className="text-center text-[2em] font-bold">
          2nd International Conference on Recent Advances in Artificial
          Intelligence & Smart Applications (RAAISA) 2024
        </h1>
        {/* ======================== ORGANIZED BY - START ======================== */}
        <p className="text-center text-[1.2em] font-medium mt-[1em] text-secondary">
          Organized by Department of CSE, CSE (AI & ML), CSE (IOT, CS, BT)
        </p>
        <p className="text-center text-[1.4em] font-medium mt-[0.2em] text-secondary">
          University of Engineering and Management, Kolkata
        </p>
        {/* ======================== ORGANIZED BY - END ======================== */}
        {/* ======================== DATE - START ======================== */}
        <p className="text-secondary text-[1.2em] font-semibold bg-tertiary w-max px-[1em] m-auto mt-[2.2em] rounded-md shadow-lg whitespace-nowrap">
          14th – 15th December, 2024
        </p>
        {/* ======================== DATE - END ======================== */}
        {/* ======================== VENUE - START ======================== */}

        <Link
          className="md:w-[80%] w-[100%] m-auto shadow-lg mt-[3em] p-[3%] rounded-lg flex flex-col  hover:scale-[1.05] duration-[0.5s] bg-white/30 hover:bg-white/90"
          to="/contact"
        >
          <p className="text-center text-[1.6em] font-medium text-secondary mb-[0.6em]">
            Venue
          </p>
          <img src={logo} alt="Uem logo" className="h-[4em] m-auto shadow-lg" />
          <h1 className="text-center mt-[1em] text-secondary text-[1.1em] font-bold">
            University of Engineering and Management, Kolkata
          </h1>
        </Link>
        {/* ======================== VENUE - END ======================== */}
        <p className="text-center text-[1.1em] font-semibold mt-[5em]">
          All accepted & presented papers of "RAAISA 2024" will be published in
          the Scopus indexed book series “Lecture Notes in Networks and Systems"
          (
          <Link
            to="https://www.springer.com/series/15179"
            target="_blank"
            className="text-blue-800 hover:text-blue-600 hover:underline"
          >
            https://www.springer.com/series/15179
          </Link>
          )
        </p>
      </div>
      <div className="min-h-[16vw] md:w-[80%] w-full bg-gray-50/60 m-auto rounded-b-[50%]" />
    </section>
  );
};

export default HeadBanner;
