import React from "react";
import Heading from "../Heading";

const NoBubbleLayout = ({ id = null, heading, children }) => {
  return (
    <section className="no_bubble_layout" id={id}>
      <Heading colorClass={"text-secondary"} title={heading} />
      {children}
    </section>
  );
};

export default NoBubbleLayout;
