import React, { useEffect } from "react";
import {
  AreaOfInterest,
  AuthorsGuidLine,
  DownloadTemplate,
  NoBubbleLayout,
} from "../components";
import springerLogo from "../assets/springer_transparent.png";
import scopusLogo from "./../assets/scopus.jpeg";
import { Link } from "react-router-dom";

const AuthorsGuidLinePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="size_text px_layout pt-[3vw]">
        <div className="flex">
          <img
            src={springerLogo}
            alt="springer logo"
            className="h-[4em] m-auto"
          />
          <img
            src={scopusLogo}
            alt="springer logo"
            className="h-[3em] m-auto"
          />
        </div>
        <p className="text-[0.8em] font-medium text-center">
          {" "}
          All accepted & presented papers of "RAAISA 2024" will be published in
          the Scopus indexed book series “Lecture Notes in Networks and Systems”
          (
          <Link
            to="https://www.springer.com/series/15179"
            target="_blank"
            className="text-blue-800 hover:text-blue-600 hover:underline"
          >
            https://www.springer.com/series/15179
          </Link>
          )
        </p>
      </div>
      <AuthorsGuidLine />
      <NoBubbleLayout heading={"Conference Tracks"}>
        <AreaOfInterest newBg={"bg-white"} noteColor="text-black" />
      </NoBubbleLayout>
      <div className="px_layout flex justify-center">
        <DownloadTemplate
          maxWidth={"max-w-[800px]"}
          textSize={"size_text mb-[3em]"}
        />
      </div>
    </div>
  );
};

export default AuthorsGuidLinePage;
