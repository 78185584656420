import React, { useEffect } from 'react';
import { CommingSoonBanner, Program } from '../components';

const ProgramPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      {/* <Program /> */}
      <CommingSoonBanner />
    </div>
  );
};

export default ProgramPage;
