import React from 'react';
import { areaOfInterestList, SUBMITTION_LINK } from '../../Data/dummy';

const AreaOfInterest = ({ noteColor }) => {
  const handleClick = () => {
    window.location = SUBMITTION_LINK;
  };
  return (
    <>
      <div className='mt-[5vw]'>
        <p
          className={`${noteColor ?? 'text-tertiary'} text-center text-[0.8em]`}
        >
          The conference invites original contributions in the fields of
          Artificial Intelligence and Smart Systems , but not limited to the
          following list:
        </p>
        <div className='w-full flex md:flex-row flex-col md:items-stretch justify-center items-center bg-gray-200 px-[1em] py-[1em] gap-[1em] mt-[1em] rounded-[0.8em] shadow-lg'>
          {areaOfInterestList.map((area, i) => (
            <ul
              className='bg-tertiary max-w-[20em] flex-1 p-[0.5em] rounded-[0.5em] shadow-md list-[circle] list-inside h-auto flex flex-col justify-between'
              key={i}
            >
              <h1 className='text-[1em] font-bold mb-[1em]'>{area.name}</h1>
              <div>
                {area.applications.map((point, j) => (
                  <li className='text-[0.8em] my-[0.5em] font-medium' key={j}>
                    {point}
                  </li>
                ))}
              </div>
            </ul>
          ))}
        </div>
        <div className='m-auto w-fit mt-[4em]'>
          <button
            className={`${
              noteColor ? 'enabled:secondary_btn' : 'enabled:tertiary_btn'
            } disabled:disabled_btn text-[0.8em] py-[0.5em] px-[1.5em] rounded-[0.2em] shadow-md`}
            onClick={handleClick}
          >
            Click to submit paper
          </button>
        </div>
      </div>
    </>
  );
};

export default AreaOfInterest;
